// import React from "react";
// import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

// export default () => (
//   <Popup trigger={error} position="right center">
//     <div>Could not login! Please try again another way!</div>
//   </Popup>
// );
import React from "react";
import "./Popup.css";

const PopupComponent = (props) => {
  return (
    <div className="popup-container">
      <div className="popup-body">
        <h1>Could not login! Please try again another way!</h1>
        <button onClick={props.closePopup}>Close X</button>
      </div>
    </div>
  );
};

export default PopupComponent;

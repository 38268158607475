import { appleClient } from "./api/apis";
import Apple from "../src/assets/img/apple.png";
import { APPLE_CLIENT_ID, APPLE_SECRET } from "./api/endpoints";
import AppleLogin from "react-apple-login";
import axios from "axios";
import AppleSignin from "react-apple-signin-auth";
import PopupComponent from "./PopupComponent";

const AppleLoginComponent = () => {
  const appleResponse = async (response) => {
    const queryParameters = new URLSearchParams(window.location.search);

    const appleCode = queryParameters.get("code");
    if (appleCode !== null) {
      localStorage.setItem("Platform", "Apple");
      localStorage.setItem("appleCode", appleCode);
      apple();
    }
  };

  //   const responseToken = await axios.post(
  //     "https://appleid.apple.com/auth/token",
  //     {
  //       client_id: APPLE_CLIENT_ID,
  //       client_secret: APPLE_SECRET,
  //       appleCode,
  //       grant_type: "authorization_code",
  //       redirect_uri: "https://babelportal-client.azurewebsites.net/",
  //     },
  //     {
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",

  //         "Access-Control-Expose-Headers": "Access-Control-Allow-Origin",
  //       },
  //     }
  //   );
  // };
  const apple = async () => {
    //console.log("th9is is response" + response);

    const queryParameters = new URLSearchParams(window.location.search);
    if (queryParameters.get("platform") === "apple") {
      localStorage.setItem("appleCode", queryParameters.get("code"));

      const appleCode = localStorage.getItem("appleCode");
      if (appleCode !== null) {
        console.log("alli3    ", appleCode);
        console.log(
          "data check :            " +
            localStorage.getItem("clientMac") +
            "     " +
            localStorage.getItem("nodeMac") +
            "    " +
            localStorage.getItem("gatewayId")
        );
        const request = await appleClient({
          platform: "Apple",
          code: localStorage.getItem("appleCode"),
          clientIp: localStorage.getItem("clientIp"),
          nodeId: localStorage.getItem("nodeId"),
          clientMac: localStorage.getItem("clientMac"),
          nodeMac: localStorage.getItem("nodeMac"),
          gatewayid: localStorage.getItem("gatewayId"),
          continueUrl: localStorage.getItem("continueUrl"),
          baseGrantUrl: localStorage.getItem("baseGrantUrl"),
        });

        console.log("ana b apple o2");

        if (request) {
          console.log("request sent " + request);
          console.log("continue url:  " + localStorage.getItem("continueUrl"));
          window.location.href =
            localStorage.getItem("baseGrantUrl") +
            "?continue_url=" +
            localStorage.getItem("continueUrl") +
            "&duration=7200";
        } else {
          //setOpen(true);
          console.log("fsdghn");

          localStorage.setItem("error", "Something went wrong! apple");
        }
        //localStorage.clear();
      }
    }
  };
  console.log("ana b apple");

  return (
    <AppleLogin
      clientId={APPLE_CLIENT_ID}
      usePopup={false}
      callback={apple} // Catch the response
      //responseType="code id_token"
      responseMode="query"
      scope="email name"
      redirectURI={
        "https://babelwifi.inspiresolutions.io/?platform=apple"
        //"https://babelportal-client.azurewebsites.net/?platform=apple"
        //"https://babelportal-api.azurewebsites.net/api/User/GetAppleLogin"
        //"https://www.google.com"
      }
      render={(renderProps) => (
        <img
          src={Apple}
          onClick={
            //(e) => {
            // localStorage.setItem("Platform", "Apple");
            renderProps.onClick
          }
          //}
          className="sso"
          style={{ cursor: "pointer" }}
          alt="icloud"
        />
      )}
    />
  );
};

export default AppleLoginComponent;

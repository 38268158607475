import axios from "axios";
import "../web.config";

//const BASE_URL = "https://localhost:7143/api";
const BASE_URL = "https://babelwifi.inspiresolutions.io/babelapi/api";

const api = axios.create({
  baseURL: BASE_URL,
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    // Add headers or perform actions before request is sent
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    // Process response data
    return response;
  },
  (error) => {
    // Handle response error
    return Promise.reject(error);
  }
);

export default api;

import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleLoginButton from "./GoogleLogin";
import { GOOGLE_CLIENT_ID } from "./api/endpoints";
import axios from "axios";
import { createClient } from "./api/apis";

const GoogleComponent = () => {
  const CreateSocialUser = async (userInfo) => {
    const request = await createClient({
      name: userInfo.name,
      email: userInfo.email,
      platformName: userInfo.Platform,
      clientIp: localStorage.getItem("clientIp"),
      nodeId: localStorage.getItem("nodeId"),
      clientMac: localStorage.getItem("clientMac"),
      nodeMac: localStorage.getItem("nodeMac"),
      gatewayid: localStorage.getItem("gatewayId"),
      continueUrl: localStorage.getItem("continueUrl"),
      baseGrantUrl: localStorage.getItem("baseGrantUrl"),
    });
    if (request === true) {
      console.log("request sent " + request);
      console.log("continue url:  " + localStorage.getItem("continueUrl"));
      window.location.href =
        localStorage.getItem("baseGrantUrl") +
        "?continue_url=" +
        localStorage.getItem("continueUrl") +
        "&duration=7200";
    } else {
      localStorage.setItem("error", "Google error");
    }
  };
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <GoogleLoginButton
        redirectUri="https://babelwifi.inspiresolutions.io/"
        className="text-center"
        onSuccess={async (credentialResponse) => {
          const userInfo = await axios
            .get("https://www.googleapis.com/oauth2/v3/userinfo", {
              headers: {
                Authorization: `Bearer ${credentialResponse.access_token}`,
              },
            })
            .then((res) => res.data);
          userInfo.Platform = "Gmail";
          CreateSocialUser(userInfo);
        }}
        onError={
          ""
          //   localStorage.setItem(
          //     "error",

          //     "Something went wrong! 232w4"
          //   )
          //setOpenPopup(true)
        }
      />
    </GoogleOAuthProvider>
  );
};
export default GoogleComponent;

import api from ".";
import { APPLE_CLIENT, CREATE_CLIENT, LINKEDIN_CLIENT } from "./endpoints";

export const createClient = async (data) => {
  const request = await api.post(CREATE_CLIENT, data);
  return request.data;
};
export const linkedInClient = async (data) => {
  const request = await api.post(LINKEDIN_CLIENT, data);
  return request.data;
};
export const appleClient = async (data) => {
  const request = await api.post(APPLE_CLIENT, data);
  return request.data;
};

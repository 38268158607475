// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    }
    .popup-body {
    position: absolute;
    left: 30%;
    right: 30%;
    bottom: 30%;
    top: 30%;
    text-align: center;
    margin: auto;
    border-radius: 15px;
    border-color: black;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }`, "",{"version":3,"sources":["webpack://./src/Popup.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC;IACA;IACA,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,WAAW;IACX,QAAQ;IACR,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;IACjB,4CAA4C;IAC5C","sourcesContent":[".popup-container {\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n    }\n    .popup-body {\n    position: absolute;\n    left: 30%;\n    right: 30%;\n    bottom: 30%;\n    top: 30%;\n    text-align: center;\n    margin: auto;\n    border-radius: 15px;\n    border-color: black;\n    background: white;\n    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
